var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.boxOpened.isOpen)?_c('div',{staticClass:"inner"},[(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 0 - 0 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 0 - 0 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 0 - 0 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 0 - 0 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 0 - 1 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 0 - 1 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 0 - 1 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 0 - 1 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 1 - 0 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 1 - 0 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 1 - 0 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 1 - 0 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 1 - 1 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 1 - 1 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 0 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('0 - 1 - 1 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('0 - 1 - 1 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 0 - 0 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 0 - 0 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 0 - 0 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 0 - 0 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 0 - 1 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 0 - 1 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 0
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 0 - 1 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 0 - 1 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 1 - 0 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 1 - 0 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 0 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 1 - 0 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 1 - 0 - 1')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 0)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 1 - 1 - 0')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 1 - 1 - 0')}})]):_vm._e(),(_vm.box.hasAppCheckin === 1 && _vm.box.hasSmsCheckin === 1
    && _vm.box.hasCardCheckin === 1 && _vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s('1 - 1 - 1 - 1')}}),_c('div',{staticClass:"text extra",domProps:{"innerHTML":_vm._s('1 - 1 - 1 - 1')}})]):_vm._e(),_c('div',{staticClass:"icons"},[(_vm.box.hasAppCheckin === 1)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.iconApp.src}})]):_vm._e(),(_vm.box.hasSmsCheckin === 1)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.iconSms.src}})]):_vm._e(),(_vm.box.hasCardCheckin === 1)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.iconCC.src}})]):_vm._e(),(_vm.box.hasNfcCheckin === 1)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":_vm.iconNfc.src}})]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }